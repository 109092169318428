











import {Component, Prop} from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import PatientCustomerService from '@/components/PatientCustomerService.vue';

@Component({
  components: {
    PatientCustomerService,
  },
})
export default class PatientCustomerServiceView extends AppComponent {
  @Prop({required: false}) public patientId: string;
}
